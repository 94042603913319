import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero/Hero";
import { MouseFollower, UpdateFollower } from "react-mouse-follower";
import FloatingContact from "./Components/FLoatingContact/FloatingContact";
import About from "./Components/AboutUs/About";
import ContactUs from "./Components/ContactUs/ContactUs";
import Products from "./Components/Products/Products";
import Services from "./Components/Services/Services";
import Footer from "./Components/Footer/Footer";
function App() {
  
  return (
<div className="overflow-x-hidden overflow-y-auto scrollbar-hide min-h-screen flex flex-col justify-between">


    
      <UpdateFollower
      mouseOptions={{
        backgroundColor: "white",
        zIndex: 999,
        followSpeed: 1.5,
      }}
      >
      
      <Navbar />
      <Hero />
      <FloatingContact />
      <About/>
      <Products/>
      <Services/>
      <ContactUs />
      <Footer />
      </UpdateFollower>
      {/* <Services /> */}
    </div>
  );
};

export default App;