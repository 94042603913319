import { useState } from "react";
import { motion } from "framer-motion";
import { MdChat, MdClose, MdEmail, MdPhone } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";

const FloatingChat = () => {
    const [showIcons, setShowIcons] = useState(false);

    // Function to check if the user is on a mobile device
    function isMobileDevice() {
      return /Mobi|Android/i.test(navigator.userAgent);
    }
  
    // Function to handle email button click
    function handleEmailClick(event) {
      event.preventDefault();
      const email = "arjun.pawase@airejuniors.com";
      const cc = "kajal.nalage@airejuniors.com";
      const subject = "Subject Here";
      const body = "Body Here";
  
      const mailtoLink = `mailto:${email}?cc=${cc}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
      if (isMobileDevice()) {
        window.location.href = mailtoLink;
      } else {
        window.open(
          `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&cc=${cc}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`,
          "_blank"
        );
      }
    }
  

  return (
    <div className="fixed bottom-4 right-4 flex flex-col items-center z-[99999]">
      {showIcons && (
        <motion.div
          className="flex flex-col items-center space-y-3 mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <motion.div
            className="bg-primary p-3 rounded-full shadow-lg text-white cursor-pointer"
            whileHover={{ scale: 1.1, rotate: 90 }}
            whileTap={{ scale: 0.9, rotate: 90 }}
            onClick={() => setShowIcons(false)}
          >
            <MdClose size={30} />
          </motion.div>

          <motion.a
            href="#"
            className="bg-gradient-to-br from-red-600 via-yellow-500 to-pink-500 p-3 rounded-full shadow-lg text-white"
            whileHover={{ scale: 1.1, rotate: 10 }}
            whileTap={{ scale: 0.9, rotate: 10 }}
            onClick={handleEmailClick}
          >
            <MdEmail size={30} />
          </motion.a>

          <motion.a
            href="https://www.instagram.com/smakcoders/"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gradient-to-br from-purple-700 via-pink-600 to-orange-600 p-3 rounded-full shadow-lg text-white"
            whileHover={{ scale: 1.1, rotate: 10 }}
            whileTap={{ scale: 0.9, rotate: 10 }}
          >
            <FaInstagram size={30} />
          </motion.a>

          <motion.a
            href="tel:+919561190627"
            className="bg-blue-600 p-3 rounded-full shadow-lg text-white"
            whileHover={{ scale: 1.1, rotate: 10 }}
            whileTap={{ scale: 0.9, rotate: 10 }}
          >
            <MdPhone size={30} />
          </motion.a>

          <motion.a
            href={isMobileDevice() ? "whatsapp://send?phone=9561190627" : "https://web.whatsapp.com/send?phone=9561190627"}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-500 p-3 rounded-full shadow-lg text-white"
            whileHover={{ scale: 1.1, rotate: 10 }}
            whileTap={{ scale: 0.9, rotate: 10 }}
          >
            <ImWhatsapp color="white" size={30} />
          </motion.a>
        </motion.div>
      )}

      <motion.div
        className="text-3xl text-black bg-primary text-white p-4 rounded-full shadow-lg cursor-pointer"
        whileHover={{ scale: 1.1, rotate: 180 }}
        whileTap={{ scale: 0.9, rotate: 180 }}
        onClick={() => {
            // console.log("Chat button clicked!");
            setShowIcons(!showIcons)}}
      >
        <MdChat size={30} />
      </motion.div>
    </div>
  );
};

export default FloatingChat;
