import {motion, AnimatePresence } from 'framer-motion';
import React from 'react';
import { Link } from "react-router-dom";

const ResponsiveMenu = ({open}) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{opacity:0, y:100}}
          animate={{opacity:1,y:0}}
          exit={{opacity:0, y:-100}}
          className='absolute top-20 left-0 w-full h-screen bg-white z-10'
        >
            <div className='text-xl font-semibold uppercase bg-primary text-white py-10 m-6 rounded-3xl '>
                <ul className='flex flex-col justify-center items-center gap-10'>
                <li>
                <Link to="/" className="hover:underline">Home</Link>
              </li>
              <li>
                <Link to="#about" className="hover:underline">About Us</Link>
              </li>
              <li>
                <Link to="#products" className="hover:underline">Products</Link>
              </li>
              <li>
                <Link to="#services" className="hover:underline">Services</Link>
              </li>
              <li>
                <Link to="#contact" className="hover:underline">Contact</Link>
              </li>
                </ul>

            </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
  


export default ResponsiveMenu;