import React, { useState } from 'react';
import { NavbarMenu } from './NavbarMenu';
// import { FiSearch } from "react-icons/fi";
import { HiMiniCodeBracket } from "react-icons/hi2";
// import { CiSearch } from 'react-icons/ci';
import { MdMenu } from "react-icons/md";
import ResponsiveMenu from './ResponsiveMenu';
import { UpdateFollower } from 'react-mouse-follower';
import {motion} from 'framer-motion';

{/* <FiSearch /> */}
const Navbar =()=>{
    const [open,setOpen] = React.useState(false);
    return (
    <> 
    <nav>
        <div className=" flex justify-between items-center bg-brandDark text-white fontFamily-playfair">
            {/* Logo section */}
             <div className='text-2xl flex items-center gap-2 font-bold py-2 px-4 uppercase'> 
               {/* <HiMiniCodeBracket />
                <p >Smak</p>
                <p className='text-primary'>Coders</p> */} 
                 <div className="flex items-center">
            <img src="/Logo.png" alt="SmakCoders Logo" className="h-20 w-auto" />
          </div>

            </div>
            {/* menu section */}
            <div className='hidden md:block'>
                <ul className='flex items-center gap-6 text-primary px-8 '>
                    {NavbarMenu.map((item)=>{
                        return(
                            <li key={item.id} >
                                <UpdateFollower
                                 mouseOptions={{backgroundColor:"white",
                                    zIndex:999,
                                    followSpeed:1.5,
                                    scale:5,
                                    mixBlendMode:"difference",}}>
                                <a href={item.link} className="inline-block py-1 px-3 hover:text-white font-semibold">
                                    {item.title}</a>
                                    </UpdateFollower>
                            </li>
                        )
                    })}
                </ul>

            </div>
            {/* icons section */}
            {/* <UpdateFollower
                                 mouseOptions={{backgroundColor:"white",
                                    zIndex:999,
                                    followSpeed:1.5,
                                    scale:5,
                                    mixBlendMode:"difference",}}>
            <div className='flex items-center gap-4'>
                <div className="flex items-center bg-white rounded-full px-5 py-1">
                    <input type="text" placeholder="Search" className="bg-transparent focus:outline-none" />
                    </div>
                <button className="text-2xl hover:bg-primary hover:text-white rounded-full p-2 duration-200" >
                    <CiSearch/>
                </button>
                {/* </motion.div> 
                <button className=" hover:bg-primary hover:text-white rounded-md border-2 border-primary mx-4 px-6 py-2 duration-200 hidden md:block" >
                    Login
                </button>
            </div>
            </UpdateFollower>
            {/* mobile hamburger menu section */}
            <div className="md:hidden px-4" onClick={()=> setOpen(!open)}>
            <MdMenu className='text-4xl' />
            </div>
        </div>
    </nav>
    {/* mobile sidebar section  */}
    <ResponsiveMenu open={open}/>
    </>
    )
};
export default Navbar;