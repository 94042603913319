import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { FaRocket, FaBullseye, FaBrain, FaCode, FaUsers, FaMobileAlt } from "react-icons/fa";
import { UpdateFollower } from "react-mouse-follower";

const AboutUsSlidingCards = () => {
  const sliderRef = useRef(null);

  const cards = [
    { 
      icon: <FaRocket className="text-6xl text-purple-600 mx-auto" />, 
      title: " Our Mission", 
      description: "Leverage AI and data-driven insights to create an adaptive, efficient, and user-friendly platform that enhances learning, skill-building, and job readiness.",
      tagline: "Empowering growth through AI"
    },
    { 
      icon: <FaBullseye className="text-6xl text-red-500 mx-auto" />, 
      title: " Our Vision", 
      description: "To become the go-to platform for individuals looking to boost their careers, providing end-to-end solutions from skill development to interview success.",
      tagline: "Your career, our commitment"
    },
    { 
      icon: <FaBrain className="text-6xl text-blue-500 mx-auto" />, 
      title: " AI-Powered Learning", 
      description: "Get customized training based on your strengths & weaknesses using AI-driven insights.",
      tagline: "Smart learning for smart minds"
    },
    { 
      icon: <FaCode className="text-6xl text-green-500 mx-auto" />, 
      title: " Industry-Standard Assessments", 
      description: "Prepare with real-world coding challenges & aptitude tests designed for job readiness.",
      tagline: "Real challenges, real skills"
    },
    { 
      icon: <FaUsers className="text-6xl text-orange-500 mx-auto" />, 
      title: " Interview Readiness", 
      description: "AI-based mock interviews, resume building, and communication training to help you succeed.",
      tagline: "Nail every interview"
    },
    { 
      icon: <FaMobileAlt className="text-6xl text-pink-500 mx-auto" />, 
      title: " User-Friendly & Accessible", 
      description: "A seamless learning experience on both mobile & web with a clean and interactive UI.",
      tagline: "Learn anywhere, anytime"
    },
  ];

  useEffect(() => {
    const slider = sliderRef.current;
    const totalWidth = slider.scrollWidth / 2;

    gsap.to(slider, {
      x: `-${totalWidth}px`, 
      duration: 35, 
      ease: "linear",
      repeat: -1, 
    });
  }, []);

  return (
    <div className="overflow-hidden bg-gray-100 py-10 relative">
      <div ref={sliderRef} className="flex w-max">
        {[...cards, ...cards].map((card, index) => (
          <UpdateFollower
            key={index}
            mouseOptions={{
              backgroundColor: "white",
              zIndex: 999,
              followSpeed: 2,
              scale: 8,
              mixBlendMode: "difference",
              rotate: 360,
            }}
          >
            <div className="w-80 h-[360px] bg-white shadow-xl rounded-xl p-6 mx-4 flex-shrink-0 flex flex-col items-center justify-between text-center space-y-4 border border-gray-200">
              <div className="flex flex-col items-center space-y-3">
                {card.icon}
                <h3 className="text-xl font-bold">{card.title}</h3>
              </div>
              <p className="text-gray-800 text-sm leading-relaxed">{card.description}</p>
              <hr className="w-1/2 border-gray-300" />
              <p className="text-xs text-gray-800 italic font-bold">{card.tagline}</p>
            </div>
          </UpdateFollower>
        ))}
      </div>
    </div>
  );
};

export default AboutUsSlidingCards;
