import React from "react";
import AboutUsSlidingCards from "./AboutUsSlidingCards";
import { UpdateFollower } from "react-mouse-follower";

const AboutUs = () => {
  return (
    <section id="about" className="bg-gray-100 py-16 px-6 md:px-12">
      <div className="max-w-7xl mx-auto text-center">
        <UpdateFollower
          mouseOptions={{
            backgroundColor: "white",
            zIndex: 999,
            followSpeed: 1.5,
            scale: 10,
            mixBlendMode: "difference",
            rotate: 360,
          }}
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-6">📌 About Us</h2>
        </UpdateFollower>

        <p className="text-lg text-gray-700 max-w-3xl mx-auto">
          At <strong>SMAKCODERS</strong>, we are dedicated to revolutionizing
          career development through cutting-edge AI-driven solutions. Our goal
          is to empower students, job seekers, and professionals with the right
          tools to succeed in today's competitive job market.
        </p>
      </div>

      {/* Sliding Cards Section */}
      <div className="mt-12">
        <AboutUsSlidingCards />
      </div>
    </section>
  );
};

export default AboutUs;
