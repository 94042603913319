import React, { useEffect,useRef,useLayoutEffect } from "react";
import heroImg from "../Assets/heroImg.png";
import { ImWhatsapp } from "react-icons/im";
import { easeInOut,AnimatePresence, motion } from "framer-motion";
import { UpdateFollower } from "react-mouse-follower";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { easeIn } from "framer-motion/dom";
const fadeUp = (delay) => {
  
  return {
    hidden: { opacity: 0, y: 100,
      scale:0.5
     },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        duration: 0.5,
        ease: easeInOut,
      },
    },
    exit:{
      opacity:0,
      y:50,
      transition:{
        duration:0.2,
        ease:easeInOut
    },
  },
};
};


const AnimatedImage = ({src,alt,className }) => {
  const imgRef = useRef(null);

  useEffect(()=>{
    gsap.fromTo(
      imgRef.current,
      {
        opacity:0,
        y:100,
        scale:0.8,
      },
      {
        opacity:1,
        y:0,
        scale:1,
        duration:1,
        ease:"power3.inOut",
        scrollTrigger:{
          trigger:imgRef.current,
          start:"top 80%",
          end:"top 20%",
          toggleActions:"play none none reverse",
        },
      }
    );
  },[]);
  return (
    <UpdateFollower
      mouseOptions={{
        backgroundColor: "white",
        zIndex: 999,
        followSpeed: 1.5,
        scale: 10,
        mixBlendMode: "difference",
        rotate: 360,
      }}
    >
      <img ref={imgRef} src={src} alt={alt} className={`mx-auto ${className}`} />
    </UpdateFollower>
  );
};
const Hero = () => {
  return (
    <>
      <section id="hero" className="relative h-screen w-full flex items-center bg-brandDark text-white">
        <div className="grid grid-cols-1 md:grid-cols-2 h-full w-full">
          <div className="container flex flex-col justify-center py-32 font-playfair px-8 md:px-16">
            {/* Info */}
            <div className="text-center md:text-left space-y-8">
              <AnimatePresence mode="wait">
                <UpdateFollower
                  mouseOptions={{
                    backgroundColor: "white",
                    zIndex: 999,
                    followSpeed: 1.5,
                    scale: 10,
                    mixBlendMode: "difference",
                    rotate: -720,
                  }}
                >
                  <motion.h1
                    variants={fadeUp(0.2)}
                    animate="show"
                    className="text-4xl lg:text-6xl font-bold leading-relaxed xl:leading-normal"
                  >
                    <span className="text-primary">SmakCoders</span> is your
                    gateway to cutting-edge AI and coding solutions.
                  </motion.h1>
                </UpdateFollower>
              </AnimatePresence>

              <p className="text-gray-300 text-lg xl:max-w-[600px]">
                From AireJunior for AI-driven learning to AirePharma for
                revolutionizing healthcare, we deliver powerful, scalable, and
                intelligent technology solutions tailored to your needs.
              </p>
            </div>
          </div>

          {/* Hero Image */}
          <div className="hidden md:flex justify-center items-center">
            <AnimatedImage src={heroImg} alt="hero image" className="w-[350px] md:w-[450px] xl:w-[550px] mx-8"/>
          </div>

          {/* WhatsApp Icon */}
          {/* <div className="text-4xl fixed bottom-10 right-10 hover:rotate-[360deg] duration-300 z-[99999] mix-blend-difference">
            <a href="#">
              <ImWhatsapp />
            </a>
          </div> */}
        </div>
      </section>
    </>
  );
};
export default Hero;