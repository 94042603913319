import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter  } from "react-router-dom";
import { MouseFollower } from "react-mouse-follower";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter> 
  <MouseFollower
      mouseOptions={{
        backgroundColor: "white",
        zIndex: 9999, // Ensure it's above other elements
        followSpeed: 2,
        scale: 1.5,
        mixBlendMode: "difference",
      }}
    />
      <App />
    </BrowserRouter>
);

reportWebVitals();
