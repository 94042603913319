export const NavbarMenu =[
    {
        id:1,
        title:"Home",
        link:"/"

    },
    {
        id:2,
        title:"About Us",
        link:"#about"

    },
    {
        id:3,
        title:"Products",
        link:"#products"

    },
    {
        id:4,
        title:"Services",
        link:"#services"

    },
    {
        id:5,
        title:"Contact",
        link:"#contact"

    },
]