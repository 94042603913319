import { useState } from "react";
import { motion } from "framer-motion";
import { MdClose, MdEmail, MdPhone, MdChat } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";

const Footer = () => {
  const [showIcons, setShowIcons] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("terms");

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Footer Section */}
      {/* Footer Section */}
     <motion.footer
        className="bg-brandDark text-white py-10  px-6 sm:px-12 md:px-20 text-center relative overflow-hidden"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
      >
        <div className="text-center mb-6">
          <p className="text-gray-400">&copy; 2025 SmakCoders. All Rights Reserved. | Developed by 
            <span className="text-orange-400"> SMAKCODERS</span>
          </p>
        </div>

        {/* Floating Action Button */}
        <div className="fixed bottom-4 right-4 flex flex-col items-center z-[99999]">
          {showIcons && (
            <motion.div
              className="flex flex-col items-center space-y-3 mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <motion.div
                className="bg-red-500 p-3 rounded-full shadow-xl text-white cursor-pointer"
                whileHover={{ scale: 1.1, rotate: 90 }}
                whileTap={{ scale: 0.9, rotate: 90 }}
                onClick={() => setShowIcons(false)}
              >
                <MdClose size={30} />
              </motion.div>

              <motion.a
                href="#"
                className="bg-gradient-to-br from-red-600 via-yellow-500 to-pink-500 p-3 rounded-full shadow-xl text-white"
                whileHover={{ scale: 1.1, rotate: 10 }}
                whileTap={{ scale: 0.9, rotate: 10 }}
              >
                <MdEmail size={30} />
              </motion.a>

              <motion.a
                href="https://www.instagram.com/smakcoders/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gradient-to-br from-purple-700 via-pink-600 to-orange-600 p-3 rounded-full shadow-xl text-white"
                whileHover={{ scale: 1.1, rotate: 10 }}
                whileTap={{ scale: 0.9, rotate: 10 }}
              >
                <FaInstagram size={30} />
              </motion.a>

              <motion.a
                href="tel:+919561190627"
                className="bg-blue-600 p-3 rounded-full shadow-xl text-white"
                whileHover={{ scale: 1.1, rotate: 10 }}
                whileTap={{ scale: 0.9, rotate: 10 }}
              >
                <MdPhone size={30} />
              </motion.a>

              <motion.a
                href="https://web.whatsapp.com/send?phone=9561190627"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-green-500 p-3 rounded-full shadow-xl text-white"
                whileHover={{ scale: 1.1, rotate: 10 }}
                whileTap={{ scale: 0.9, rotate: 10 }}
              >
                <ImWhatsapp size={30} />
              </motion.a>
            </motion.div>
          )}

          <motion.div
            className="text-3xl bg-orange-500 text-white p-4 rounded-full shadow-xl cursor-pointer"
            whileHover={{ scale: 1.1, rotate: 180 }}
            whileTap={{ scale: 0.9, rotate: 180 }}
            onClick={() => setShowIcons(!showIcons)}
          >
            <MdChat size={30} />
          </motion.div>
        </div>

        {/* Legal Information */}
        <p className="text-gray-300 text-sm sm:text-base mb-6 px-4">
          By using our platform, you agree to our{" "}
          <motion.span
            className="text-orange-400 hover:text-orange-300 transition-all cursor-pointer"
            whileHover={{ scale: 1.1 }}
            onClick={() => openModal("terms")}
          >
            Terms & Conditions
          </motion.span>{" "}
          and{" "}
          <motion.span
            className="text-orange-400 hover:text-orange-300 transition-all cursor-pointer"
            whileHover={{ scale: 1.1 }}
            onClick={() => openModal("privacy")}
          >
            Privacy Policy
          </motion.span>.
        </p>
      </motion.footer>

      {/* Modal Component */}
      {isModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 px-4">
      <motion.div
        className="bg-white max-w-2xl w-full rounded-lg shadow-lg overflow-hidden"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        {/* Modal Header */}
        <div className="flex justify-between items-center bg-orange-500 text-white px-6 py-4">
          <h2 className="text-xl font-semibold">
            {modalContent === "terms" ? "Terms & Conditions" : "Privacy Policy"}
          </h2>
          <button onClick={closeModal} className="hover:text-gray-300">
            <MdClose size={24} />
          </button>
        </div>

        {/* Modal Body */}
        <div className="p-6 max-h-[70vh] overflow-y-auto text-gray-700 space-y-6 text-sm leading-relaxed">
          {modalContent === "terms" ? (
            <>
              <h3 className="text-lg font-semibold text-gray-900">1. Introduction</h3>
<p>
  Welcome to <strong>SmakCoders</strong>! These Terms and Conditions (&ldquo;Terms&rdquo;) govern your access to and use of our platform. By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree, please discontinue use immediately.
</p>

<h3 className="text-lg font-semibold text-gray-900">2. User Eligibility</h3>
<ul className="list-disc pl-5">
  <li>Users must be at least <strong>18 years old</strong> or have verifiable parental/guardian consent.</li>
  <li>Users must provide accurate, current, and complete information during registration.</li>
</ul>

<h3 className="text-lg font-semibold text-gray-900">3. Account Responsibilities</h3>
<p>
  Users are solely responsible for maintaining the confidentiality of their account credentials. Unauthorized use of an account must be reported to <strong>SmakCoders</strong> immediately. <strong>SmakCoders</strong> shall not be liable for losses due to unauthorized access.
</p>

<h3 className="text-lg font-semibold text-gray-900">4. Services Offered</h3>
<p>Our platform provides, but is not limited to, the following services:</p>
<ul className="list-disc pl-5">
  <li>AI-powered mock interviews</li>
  <li>Resume-building assistance</li>
  <li>Coding & aptitude test preparation</li>
  <li>Email writing practice</li>
  <li>Performance tracking and analytics</li>
</ul>

<h3 className="text-lg font-semibold text-gray-900">5. Payment & Subscription</h3>
<ul className="list-disc pl-5">
  <li>Certain services require a subscription or a one-time payment.</li>
  <li>All payments are <strong>non-refundable</strong> unless explicitly stated otherwise.</li>
  <li><strong>SmakCoders</strong> reserves the right to modify pricing and will provide prior notice where applicable.</li>
</ul>

<h3 className="text-lg font-semibold text-gray-900">6. Code of Conduct</h3>
<p>Users agree to:</p>
<ul className="list-disc pl-5">
  <li>Refrain from engaging in illegal, unethical, or fraudulent activities.</li>
  <li>Avoid distributing malicious software, spam, or harmful content.</li>
  <li>Use the platform in compliance with all applicable laws and regulations.</li>
</ul>

<h3 className="text-lg font-semibold text-gray-900">7. Intellectual Property</h3>
<p>
  All content, including but not limited to text, graphics, logos, images, videos, software, and services, is the exclusive property of <strong>SmakCoders</strong>. Unauthorized use, reproduction, modification, or distribution of any content without prior written consent is strictly prohibited and may lead to legal action.
</p>

<h3 className="text-lg font-semibold text-gray-900">8. Dispute Resolution</h3>
<p>
  Any disputes, claims, or controversies arising from the use of <strong>SmakCoders</strong> shall first attempt to be resolved amicably. If unresolved, disputes shall be governed by and interpreted under the laws of <strong>India</strong> and subject to the exclusive jurisdiction of the courts in <strong>Pune, Maharashtra</strong>.
</p>

<h3 className="text-lg font-semibold text-gray-900">9. Limitation of Liability</h3>
<p>
  To the maximum extent permitted by law, <strong>SmakCoders</strong> shall not be liable for any direct, indirect, incidental, consequential, or punitive damages, including but not limited to data loss, service disruptions, or unauthorized account access.
</p>

<h3 className="text-lg font-semibold text-gray-900">10. Termination of Services</h3>
<p>
  <strong>SmakCoders</strong> reserves the right to suspend or terminate user accounts at its sole discretion if a user is found violating these Terms, engaging in fraudulent activity, or abusing platform services.
</p>

<h3 className="text-lg font-semibold text-gray-900">11. Amendments to Terms</h3>
<p>
  <strong>SmakCoders</strong> may update these Terms at any time. Continued use of the platform after amendments constitutes acceptance of the revised Terms.
</p>

<h3 className="text-lg font-semibold text-gray-900">12. Contact Information</h3>
<p>
  For any questions or concerns regarding these Terms, please contact us at <strong>support@smakcoders.com</strong>
</p>

            </>
          ) : (
            <>
  <h3 className="text-lg font-semibold text-gray-900">1. Data Collection</h3>
  <p>
    <strong>SmakCoders</strong> is committed to protecting user privacy and collects the following types of data to enhance our services:
  </p>
  <ul className="list-disc pl-5">
    <li><strong>Personal Information:</strong> Name, email, contact details, and other identifiers necessary for account creation and communication.</li>
    <li><strong>Performance Data:</strong> Records of user performance in mock interviews and tests to provide personalized feedback and improvements.</li>
    <li><strong>Payment Information:</strong> Securely processed by third-party payment gateways (e.g., Stripe, Razorpay). We do not store sensitive financial details.</li>
    <li><strong>Cookies & Usage Analytics:</strong> Information on how users interact with our platform to improve functionality and user experience.</li>
  </ul>

  <h3 className="text-lg font-semibold text-gray-900">2. Data Usage</h3>
  <p>We utilize collected data to:</p>
  <ul className="list-disc pl-5">
    <li>Enhance AI-driven interview simulations and user experience.</li>
    <li>Analyze trends, usage, and behavior to optimize our services.</li>
    <li>Send essential updates, promotional materials, and policy changes.</li>
    <li>Prevent fraudulent activities and ensure compliance with regulations.</li>
  </ul>

  <h3 className="text-lg font-semibold text-gray-900">3. Data Sharing & Third-Party Services</h3>
  <p>
    We <strong>never</strong> sell or rent personal data. However, we may share necessary information with:
  </p>
  <ul className="list-disc pl-5">
    <li><strong>Payment Processors:</strong> Secure third-party gateways for transaction processing.</li>
    <li><strong>Analytics Providers:</strong> Services like Google Analytics and Firebase to improve our platform.</li>
    <li><strong>Legal Authorities:</strong> If required by law, court order, or to prevent fraudulent activities.</li>
  </ul>

  <h3 className="text-lg font-semibold text-gray-900">4. Data Security</h3>
  <p>
    We employ industry-standard security measures, including encryption, firewalls, and regular security audits to protect user data. However, no system can guarantee 100% security.
  </p>
  <p>
    Users are responsible for maintaining the confidentiality of their login credentials and should report any unauthorized access immediately.
  </p>

  <h3 className="text-lg font-semibold text-gray-900">5. Cookies & Tracking</h3>
  <p>
    Cookies help enhance your experience by remembering preferences and tracking usage. You can control cookie settings through your browser, though some features may be limited if disabled.
  </p>

  <h3 className="text-lg font-semibold text-gray-900">6. User Rights</h3>
  <p>Users have the following rights regarding their personal data:</p>
  <ul className="list-disc pl-5">
    <li><strong>Access & Portability:</strong> Request a copy of stored personal data.</li>
    <li><strong>Correction & Updates:</strong> Modify inaccurate or incomplete data.</li>
    <li><strong>Deletion Requests:</strong> Request removal of personal data, subject to legal obligations.</li>
    <li><strong>Opt-Out Options:</strong> Unsubscribe from marketing emails and control data collection preferences.</li>
  </ul>
  <p>
    To exercise these rights, contact us at <strong>privacy@smakcoders.com</strong>. Requests will be processed within a reasonable timeframe, as required by law.
  </p>

  <h3 className="text-lg font-semibold text-gray-900">7. Policy Updates</h3>
  <p>
    We may update this Privacy Policy periodically. Users will be notified via email or platform announcements of significant changes.
  </p>

  <h3 className="text-lg font-semibold text-gray-900">8. Contact Information</h3>
  <p>For any privacy concerns or inquiries, reach out to us:</p>
  <ul className="list-disc pl-5">
    <li><strong>Email:</strong> support@smakcoders.com</li>
    <li><strong>Phone:</strong> +91 9561190627</li>
  </ul>
</>

          )}
        </div>

        {/* Modal Footer */}
        <div className="bg-gray-100 px-6 py-4 flex justify-end">
          <button onClick={closeModal} className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition">
            Close
          </button>
        </div>
      </motion.div>
    </div>
)}

    </>
  );
} 
export default Footer;