import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MdEmail } from "react-icons/md";
import { UpdateFollower } from "react-mouse-follower";

gsap.registerPlugin(ScrollTrigger);

const ContactUs = () => {
  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current) {
      gsap.fromTo(
        formRef.current,
        { opacity: 0.2, y: 50 }, // Prevent complete invisibility
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: formRef.current,
            start: "top 90%", // Starts animation when form is almost in view
            toggleActions: "play none none none",
          },
        }
      );
    }
  }, []);

  return (
      <UpdateFollower
            mouseOptions={{
              backgroundColor: "white",
              zIndex: 999,
              followSpeed: 1.5,
              scale: 10,
              mixBlendMode: "difference",
              rotate: 360,
              }}
            >
    <section id="contact" className="py-16 bg-brandDark text-white text-center">
      <div className="w-full max-w-[800px] mx-auto px-4">
        {/* Heading */}
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
          className="text-3xl md:text-4xl font-bold text-primary mb-4"
        >
          Contact Us
        </motion.h2>

        <p className="text-gray-300 mb-6">
          If you have any doubts, feel free to contact us! Fill in the form below.
        </p>

        {/* Contact Form with GSAP Animation */}
        <div ref={formRef} className="bg-brandDark text-black p-6 rounded-lg shadow-lg">
          <form className="flex flex-col gap-4">
            <input
              type="text"
              placeholder="Your Name"
              className="border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
            <input
              type="email"
              placeholder="Your Email"
              className="border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
            <textarea
              rows="4"
              placeholder="Your Message"
              className="border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-primary"
              required
            ></textarea>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-primary text-white font-semibold py-3 rounded-md w-full transition-all duration-300"
            >
              Send Message 
            </motion.button>
          </form>
        </div>
      </div>
    </section>
    </UpdateFollower>
  );
};

export default ContactUs;
