import React from "react";
import { motion } from "framer-motion";
import { UpdateFollower } from "react-mouse-follower";
import { FaLaptopCode, FaGlobe, FaShoppingCart, FaMobileAlt, FaCloud, FaShieldAlt } from "react-icons/fa";
// import { easeInOut,AnimatePresence, motion } from "framer-motion";

const services = [
  { icon: <FaLaptopCode className="text-blue-500" />, title: "Custom Websites", description: "We craft stunning, high-performance websites tailored to your needs." },
  { icon: <FaGlobe className="text-green-500" />, title: "Web Applications", description: "From simple dashboards to complex SaaS platforms, we build scalable web apps." },
  { icon: <FaShoppingCart className="text-yellow-500" />, title: "E-Commerce Solutions", description: "Robust online stores with seamless payment integrations and smooth UX." },
  { icon: <FaMobileAlt className="text-red-500" />, title: "Responsive Design", description: "Your website will look amazing on any device with our mobile-first approach." },
  { icon: <FaCloud className="text-purple-500" />, title: "Cloud & Hosting", description: "Secure cloud hosting solutions with high uptime and performance optimization." },
  { icon: <FaShieldAlt className="text-orange-500" />, title: "Security & Optimization", description: "Protect your site with advanced security features and performance enhancements." },
];


const Services = () => {
  console.log("Mouse Follower applied"); 

  return (

    <UpdateFollower
    mouseOptions={{
      backgroundColor: "white",
      zIndex: 999,
      followSpeed: 1.5,
      scale: 10,
      mixBlendMode: "difference",
      rotate: 360,
      }}
    >
      <section id="services" className="py-16 bg-white text-black">
        <div className="container mx-auto text-center px-6">
          <motion.h2
            className="text-4xl font-extrabold text-brandDark mb-12"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
             Our Services
          </motion.h2>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={index}
                className="bg-white shadow-lg border-l-4 border-primary p-6 rounded-xl flex flex-col items-center text-center hover:shadow-2xl transition-all duration-300"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ scale: 1.05, rotate: 3 }}
                transition={{ delay: index * 0.1, duration: 0.4 }}
              >
                <div className="text-primary text-5xl">{service.icon}</div>
                <h3 className="text-xl font-bold mt-4">{service.title}</h3>
                <p className="text-gray-600 mt-2">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </UpdateFollower>

  );
};

export default Services;
