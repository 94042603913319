import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { UpdateFollower } from "react-mouse-follower";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

const Products = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const expandedVariants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: { opacity: 1, scale: 1, transition: { duration: 0.4 } },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } },
  };

  const cards = [
    {
      title: "Mock Interviews",
      desc: "Real-time AI-driven interview practice with instant feedback.",
    },
    {
      title: "Coding Challenges",
      desc: "Hands-on coding exercises to sharpen your problem-solving skills.",
    },
    {
      title: "Aptitude Mastery",
      desc: "Boost your logical reasoning and problem-solving speed.",
    },
    {
      title: "Resume Builder",
      desc: "Create ATS-friendly resumes that get noticed by recruiters.",
    },
    {
      title: "Email Writing",
      desc: "Improve professional email writing with AI-driven suggestions.",
    },
    {
      title: "Smart Insights",
      desc: "Track progress and receive AI-powered learning recommendations.",
    },
  ];

  return (
    <UpdateFollower
      mouseOptions={{
        backgroundColor: "white",
        zIndex: 999,
        followSpeed: 1.5,
        scale: 10,
        mixBlendMode: "difference",
        rotate: 360,
      }}
    >
      <section
        id="products"
        className="relative py-32 px-6 bg-black text-white flex flex-col justify-center items-center overflow-hidden"
      >
        <div className="container mx-auto text-center relative z-10">
          <motion.h2
            className="text-[8vw] sm:text-5xl md:text-6xl font-extrabold text-transparent bg-clip-text bg-white drop-shadow-lg mb-10 text-center max-w-[90vw]"
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Explore AireJuniors
          </motion.h2>
          <motion.p
            className="text-lg sm:text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto mb-10"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            AireJuniors is an AI-powered learning platform designed for students
            and professionals to enhance their skills. Get real-time feedback and smart insights to improve your
            performance!
          </motion.p>

          <Swiper
            modules={[Pagination, Navigation, EffectCoverflow]}
            effect="coverflow"
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={1}
            loop={true}
            pagination={{ clickable: true }}
            navigation={{ clickable: true }}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            className="w-full max-w-5xl relative"
          >
            {cards.map((item, index) => (
              <SwiperSlide key={index}>
                <motion.div
                  className="relative p-6 bg-primary text-black rounded-2xl text-center space-y-4 cursor-pointer transition-all hover:scale-105 shadow-lg"
                  onClick={() =>
                    setSelectedCard(selectedCard === index ? null : index)
                  }
                >
                  <h3 className="text-2xl font-semibold">{item.title}</h3>
                  {selectedCard === index && (
                    <AnimatePresence>
                      <motion.p
                        className="text-lg"
                        variants={expandedVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                      >
                        {item.desc}
                      </motion.p>
                    </AnimatePresence>
                  )}
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
      <style jsx global>{`
        /* Global arrow styles */
        .swiper-button-prev,
        .swiper-button-next {
          color: white !important;
          font-weight: bold;
          top: 75%;
          transform: translateY(-50%);
          transition: all 0.3s ease-in-out;
        }
        @media (max-width: 768px) {
          .swiper-button-prev {
            left: -40px;
          }
          .swiper-button-next {
            right: -40px;
          }
          .swiper-button-prev,
          .swiper-button-next {
            font-size: 14px !important;
            width: 20px !important;
            height: 20px !important;
          }
        }

        /* Add spacing between card and pagination */
        .swiper-pagination {
          margin-top: 20px;
        }
      `}</style>
    </UpdateFollower>
  );
};

export default Products;
